import * as merge from 'lodash/merge';

import { environment } from '../../../environments/environment';
import { Korona } from '../../shared/korona';

declare var korona_plugin_api: Korona;

export class Config {
  apiToken: string;
  apiBaseUrl: string;
  pos: string;
  recognitionCodes: {
    ticket: number;
    event: number;
    tour: number;
  };
  museumIds: number[];
  pageSizes: {
    tickets: number;
    orders: number;
    ordersSplitItems: number;
    eventBookings: number;
    tourBookings: number;
    dates: number;
    groupPrices: number;
    startTimes: number;
    items: number;
    prices: number;
  };
  sliceText: {
    description: number;
    comment: number;
    title: number;
    name: number;
  };
  startDate: string;
  product: {
    maxQuantityFallback: number;
  };
  tickets: {
    excludedQuotaIds: number[];
    enableOffsetSubTickets: boolean;
  };
  enableOrderPickUp: boolean;
  infoTextsEvent: string[];
  infoTextsTour: string[];
  infoTextsTicket: string[];
  currency: string;
  allowCancellation: { tickets: boolean, events: boolean, tours: boolean };
  printTicketsEnabled: boolean;

  constructor(public config: Object, token: string) {
    this.apiToken = token;
    this.apiBaseUrl = `https://${environment.domain}/api/v4`;
    this.recognitionCodes = {
      ticket: 10001,
      event: 10002,
      tour: 10003,
    };
    this.museumIds = [1];
    this.pageSizes = {
      tickets: 4,
      orders: 4,
      ordersSplitItems: 4,
      eventBookings: 2,
      tourBookings: 3,
      dates: 3,
      groupPrices: 4,
      startTimes: 26,
      items: 3,
      prices: 3,
    };
    this.sliceText = {
      description: 150,
      comment: 50,
      title: 40,
      name: 20,
    };
    this.startDate = '';
    this.product = {
      maxQuantityFallback: 99,
    };
    this.tickets = {
      excludedQuotaIds: [],
      enableOffsetSubTickets: false
    };
    this.enableOrderPickUp = true;
    if (typeof korona_plugin_api !== 'undefined' && korona_plugin_api.request) {
      this.pos = korona_plugin_api.request.pos.number;
    } else {
      this.pos = '1';
    }
    this.infoTextsEvent = ['item.attributes.startDate'];
    this.infoTextsTour = ['item.attributes.participantsFormatted', 'item.attributes.startDate'];
    this.infoTextsTicket = ['item.attributes.startTime'];
    this.currency = 'EUR';
    this.allowCancellation = {
      tickets: true,
      events: true,
      tours: false
    }
    this.printTicketsEnabled = false;
    merge(this, config);
  }
}
