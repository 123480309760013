import * as moment from 'moment';
import { Barcode, Location } from '../../shared/gomus';
import { AccountingArticle } from './order';

export class EventItem {
  id: number;
  type: string;
  price_cents: number;
  price: number;
  priceFormatted: string;
  totalPriceFormatted?: string;
  vat_pct: number;
  tax_included: boolean;
  splitable: boolean;
  split?: boolean;
  attributes: {
    id: number;
    date_id: number;
    event_id: number;
    title: string;
    quantity?: number;
    quantities?: [any];
    start_time: string;
    startTime: string;
    startDate: string;
    location: Location;
    barcode: Barcode;
    barcodes: Barcode[];
    canceled_at: string;
    accounting_article_numbers: string[];
    accounting_article_usings: AccountingArticle[];
  };

  constructor(event) {
    Object.assign(this, event);

    if (!this.tax_included) {
      this.price_cents = this.price_cents * (1 + this.vat_pct / 100);
    }
    this.tax_included = true;
    this.price = this.price_cents / 100;
    this.priceFormatted = this.price.toFixed(2);
    this.attributes.startDate = moment(this.attributes.start_time).format('LLL');
    this.attributes.startTime = moment(this.attributes.start_time).format('HH:mm');

    if (this.attributes.quantities) {
      this.attributes.quantities.forEach((price, index) => {
        this.attributes.quantities[index].priceFormatted = (price.price_cents / 100).toFixed(2);
        this.attributes.quantities[index].totalPriceFormatted = (price.total_price_cents / 100).toFixed(2);
      });
    } else if (this.attributes.quantity) {
      // TODO: @doug change to quantities format?
      this.priceFormatted = (this.price_cents / this.attributes.quantity / 100).toFixed(2);
      this.totalPriceFormatted = (this.price_cents / 100).toFixed(2);
    }
    this.initAccountingArticles();
  }

  initAccountingArticles() {
    this.attributes.accounting_article_usings.forEach(accountingArticle => {
      accountingArticle.price_cents = accountingArticle.original_price_cents;
      // update price_cents with discount
      if (accountingArticle.discount_price_cents) {
        accountingArticle.price_cents = accountingArticle.original_price_cents - accountingArticle.discount_price_cents;
      }
      // tax should always be included in price
      if (!this.tax_included) {
        accountingArticle.price_cents = accountingArticle.price_cents * (1 + accountingArticle.vat_pct / 100);
      }
    });
    // override tax_included
    this.tax_included = true;
  }
}
