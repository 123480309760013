import * as filter from 'lodash/filter';
import * as moment from 'moment';

import { EventItem } from './event-item';
import { TicketItem } from './ticket-item';
import { TourItem } from './tour-item';
import { CouponItem } from './coupon-item';
import { Config } from '../../shared/gomus/config';

export class AccountingArticle {
  number: string;
  quantity: number;
  original_price_cents: number;
  vat_pct: number;
  discount_price_cents: number;
  price_cents?: number;
  scale_price_id?: number;
  seat_id?: number;
  is_sub: boolean;
}

type OrderSource = 'gomus' | 'import' | 'onlineshop' | 'cashpoint' | 'resellershop' | 'resellerapi' | 'widget';

export class Order {
  id: number;
  token: string;
  is_valid: boolean;
  canceled: boolean;
  total_price_cents: number;
  totalPriceFormatted: string;
  payment_status: number;
  payment_mode: {
    id: number;
    name: string;
  };
  cash_point_comment: string | null;
  customer_comment: string | null;
  reference: string | null;
  rating: string | null;
  invoice: boolean;
  createdAt: string;
  items;
  customer: any;
  updateable_by_cash_point: boolean;
  splitable: boolean;
  billing_address_id: number;
  korona_customer_number: string;
  source: OrderSource;

  paid: boolean;
  pickOrder = false;
  payable = false;

  constructor(order, config: Config) {
    Object.assign(this, order);
    this.totalPriceFormatted = (this.total_price_cents / 100).toFixed(2);
    this.createdAt = moment(order.created_at).format('LLL');
    this.items = this.initItems(this.items, config);
    if (this.payment_status === 30) {
      this.paid = true;
    } else {
      this.paid = false;
    }
    if (!this.invoice && !this.canceled) {
      if (this.paid && !this.updateable_by_cash_point) {
        this.pickOrder = true;
      } else if (this.items.length > 0 && filter(this.items, item => item.attributes.canceled_at === null).length > 0) {
        this.payable = true;
      }
    }
    if (this.source === 'onlineshop' || this.source === 'cashpoint') {
      this.payable = false;
      this.pickOrder = false;
    }
  }

  private initItems(items, config: Config) {
    const newItems = [];
    items.forEach(item => {
      switch (item.type) {
        case 'Ticket':
          newItems.push(new TicketItem(item, config));
          return;
        case 'Event':
          newItems.push(new EventItem(item));
          return;
        case 'Tour':
          newItems.push(new TourItem(item));
          return;
        case 'Coupon':
          newItems.push(new CouponItem(item));
          return;
      }
    });
    return newItems;
  }
}
