

import { Barcode } from '../../shared/gomus';
import { AccountingArticle } from './order';
import { Config } from '../../shared/gomus/config';

export class CouponItem {
  id: number;
  canceled: boolean;
  price_cents: number;
  price: number;
  priceFormatted: string;
  totalPriceFormatted?: string;
  splitable: boolean;
  tax_included: boolean;
  type: string;
  vat_pct: number;
  attributes: CouponItemAttributes;

  constructor(coupon) {
    Object.assign(this, coupon);

    if (!this.tax_included) {
      this.price_cents = this.price_cents * (1 + this.vat_pct / 100);
    }

    this.tax_included = true;
    this.price = this.price_cents / 100;
    this.priceFormatted = (this.price_cents / this.attributes.quantity / 100).toFixed(2);
    this.totalPriceFormatted = ((this.price_cents * this.attributes.quantity) / 100).toFixed(2);

    this.initAccountingArticles();
  }

  initAccountingArticles() {
    this.attributes.accounting_article_usings.forEach(accountingArticle => {
      accountingArticle.price_cents = accountingArticle.original_price_cents;
      // update price_cents with discount
      if (accountingArticle.discount_price_cents) {
        accountingArticle.price_cents = accountingArticle.original_price_cents - accountingArticle.discount_price_cents;
      }
      // tax should always be included in price
      if (!this.tax_included) {
        accountingArticle.price_cents = accountingArticle.price_cents * (1 + accountingArticle.vat_pct / 100);
      }
    });
    // override tax_included
    this.tax_included = true;
  }
}

export class CouponItemAttributes {
  id: number;
  quantity: number;
  value_cents: number;
  title: string;
  description: string;
  coupon_id: number;
  coupon_cash_point_reference_number: string;
  canceled_at: string;
  barcodes: Barcode;
  accounting_article_usings: AccountingArticle[];
}
