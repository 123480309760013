let domain = "";

if (/bsms-\w+-kasse.gomus.de/.test(document.location.host)) {
  // special case for bsms, all POS go to single API endpoint
  domain = "bsms.gomus.de";
} else {
  // default behaviour strip -kasse, -integration and .demo.kasse
  domain = document.location.host.replace('.demo.kasse', '').replace('-kasse', '').replace('-integration', '');
}

export const environment = {
  production: true,
  domain: domain,
  shopDomain: document.location.host,
  environment: 'production',
  version: 'c352c110',
  commit_sha: 'c352c110250f6a0807b217a94f45a6474b612f4b',
  rollbarAccessToken: 'e8baa210d636404ca2e7cc5370391c9f',
};
