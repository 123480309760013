import * as moment from 'moment';
import * as isEmpty from 'lodash/isEmpty';

import { Barcode, Location, Ticket } from '../../shared/gomus';
import { AccountingArticle } from './order';
import { Config } from '../../shared/gomus/config';

export class TicketItemAttributes {
  id: number;
  title: string;
  ticket_id: number;
  accounting_article_numbers: string[];
  accounting_article_usings: AccountingArticle[];
  quantity: number;
  ticket_type: string;
  start_time: string;
  startTime: string;
  startDate: string;
  sub_ticket_times?: { [key: string]: string };
  sub_ticket_quantities?: { [key: string]: number };
  sub_ticket_sales?: TicketItemAttributes[];
  location: Location;
  barcodes: Barcode[];
  after_sale_information: string;
  canceled_at: string;
  is_collective: boolean;
  is_mantle = false;
  is_offset_mantle = false;
  constructor(attributes, taxIncluded, config: Config) {
    Object.assign(this, attributes);

    this.startDate = this.start_time ? moment(this.start_time).format('LL') : null;
    this.startTime = this.start_time ? moment(this.start_time).format('LLL') : null;
    if (config.tickets.enableOffsetSubTickets) {
      if (!isEmpty(this.sub_ticket_times)) {
        this.is_mantle = true;
        this.is_offset_mantle = true;
        const times = this.sub_ticket_times;
        const keys = Object.keys(times);
        const firstIndex = moment(times[keys[0]]).isBefore(times[keys[1]]) ? 0 : 1;
        const secondIndex = firstIndex === 0 ? 1 : 0;
        const firstTime = `${moment(times[keys[firstIndex]]).format('LLL')} Uhr (${keys[firstIndex]})`;
        const secondTime = `${moment(times[keys[secondIndex]]).format('LLL')} Uhr (${keys[secondIndex]})`;
        this.startTime = `${firstTime} \n ${secondTime}`;
      }
    } else {
      if (this.sub_ticket_sales && this.sub_ticket_sales.length) {
        this.is_mantle = true;
        this.sub_ticket_sales = this.sub_ticket_sales.map(subTicket => new TicketItemAttributes(subTicket, taxIncluded, config));
      }
    }

    this.initAccountingArticles(taxIncluded);
  }

  initAccountingArticles(taxIncluded) {
    this.accounting_article_usings.forEach(accountingArticle => {
      accountingArticle.price_cents = accountingArticle.original_price_cents;
      // update price_cents with discount
      if (accountingArticle.discount_price_cents) {
        accountingArticle.price_cents = accountingArticle.original_price_cents - accountingArticle.discount_price_cents;
      }
      // tax should always be included in price
      if (!taxIncluded) {
        accountingArticle.price_cents = accountingArticle.price_cents * (1 + accountingArticle.vat_pct / 100);
      }
    });

  }
}

export class TicketItem {
  id: number;
  type: string;
  price_cents: number;
  price: number;
  priceFormatted: string;
  vat_pct: number;
  tax_included: boolean;
  splitable: boolean;
  split?: boolean;
  attributes: TicketItemAttributes;

  constructor(ticket, config: Config) {
    Object.assign(this, ticket);

    if (!this.tax_included) {
      this.price_cents = this.price_cents * (1 + this.vat_pct / 100);
    }
    this.tax_included = true;
    this.price = this.price_cents / 100;
    this.priceFormatted = this.price.toFixed(2);
    this.attributes = new TicketItemAttributes(this.attributes, this.tax_included, config);
    // override tax_included
    this.tax_included = true;
  }

}
