import * as moment from 'moment';
import { Barcode, Location, Price } from '../../shared/gomus';
import { AccountingArticle } from './order';
import { Reservation } from './reservation';

export class TourItem {
  id: number;
  type: string;
  price_cents: number;
  price: number;
  priceFormatted: string;
  vat_pct: number;
  tax_included: boolean;
  splitable: boolean;
  split?: boolean;
  attributes: {
    id: number;
    tour_id: number;
    title: string;
    participants: number;
    participantsFormatted: string;
    language: string;
    age_group: string;
    prices: Price[];
    start_time: string;
    startTime: string;
    startDate: string;
    location: Location;
    barcode: Barcode;
    reservations: Reservation[];
    comment: string;
    canceled_at: string;
    accounting_article_numbers: string[];
    accounting_article_usings: AccountingArticle[];
  };
  allowTicketCollection = true;

  constructor(tour) {
    Object.assign(this, tour);

    if (!this.tax_included) {
      this.price_cents = this.price_cents * (1 + this.vat_pct / 100);
    }
    this.tax_included = true;
    this.price = this.price_cents / 100;
    this.priceFormatted = this.price.toFixed(2);
    this.attributes.startDate = moment(this.attributes.start_time).format('LLL');
    this.attributes.startTime = moment(this.attributes.start_time).format('HH:mm');
    this.attributes.participantsFormatted = `${this.attributes.participants} x Personen`;
    if (moment(this.attributes.start_time).isSameOrBefore(moment())) {
      this.allowTicketCollection = false;
    }
    this.initAccountingArticles();
  }
  initAccountingArticles() {
    this.attributes.accounting_article_usings.forEach(accountingArticle => {
      accountingArticle.price_cents = accountingArticle.original_price_cents;
      // update price_cents with discount
      if (accountingArticle.discount_price_cents) {
        accountingArticle.price_cents = accountingArticle.original_price_cents - accountingArticle.discount_price_cents;
      }
      // tax should always be included in price
      if (!this.tax_included) {
        accountingArticle.price_cents = accountingArticle.price_cents * (1 + accountingArticle.vat_pct / 100);
      }
    });
    // override tax_included
    this.tax_included = true;
  }
}
